import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Regs: FC = () => {

    const metrics = [
        { name: "Дата и время", dataKey: "app_timestamp" },
        { name: "Номер регистрации", dataKey: "order_number" },
        { name: "Тип регистрации", dataKey: "app_type" },
        { name: "Название регистрации", dataKey: "title_value" },
        { name: "URL адрес регистрации", dataKey: "url_value" },
        { name: "utm_source регистрации", dataKey: "utm_source_value" },
        { name: "utm_medium регистрации", dataKey: "utm_medium_value" },
        { name: "utm_campaign регистрации", dataKey: "utm_campaign_value" },
        { name: "utm_content регистрации", dataKey: "utm_content_value" },
        { name: "utm_term регистрации", dataKey: "utm_term_value" },
        { name: "utm_group регистрации", dataKey: "utm_group_value" },
        { name: "Тип устройства регистрации", dataKey: "type_value" },
        { name: "Модель устройства регистрации", dataKey: "device_value" },
        { name: "Операционная система регистрации", dataKey: "os_name_value" },
        { name: "Версия операционной системы регистрации", dataKey: "os_version_value" },
        { name: "Браузер регистрации", dataKey: "browser_name_value" },
        { name: "Версия браузера регистрации", dataKey: "browser_version_value" },
        { name: "Язык браузера регистрации", dataKey: "browser_language_value" },
        { name: "Страна регистрации", dataKey: "country_name" },
        { name: "Регион регистрации", dataKey: "region_name" },
        { name: "Город регистрации", dataKey: "city_name" },
        { name: "Часовой пояс регистрации", dataKey: "utc_name" },
        { name: "IP адрес регистрации", dataKey: "ip_address" },
        { name: "Email", dataKey: "email" },
        { name: "ID пользователя", dataKey: "id" },
        { name: "Телефон", dataKey: "phone" },
        { name: "Имя", dataKey: "name" },
        { name: "scan_id", dataKey: "scan_id" },
        { name: "ID сессии", dataKey: "session_id" },
        { name: "ID хита", dataKey: "hit_id" },
        { name: "URL адрес регистрации (для отладки)", dataKey: "app_url_value" },
        { name: "Хеш (для отладки)", dataKey: "hash" },
        { name: "ID обьявления", dataKey: "ad_id" },
        { name: "Статус привязки к объявлениям", dataKey: "processing_status" },
    ]

    return (
        <MainLayout pageTitle="Регистрации">
            <div className="page-wrapper">
                <PageHead
                    title="Регистрации"
                    description={`
                        Отчёт, который позволяет посмотреть и выгрузить нужные регистрации пользователей онлайн-школы. Подробнее.    
                    `}
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Пользователи", "Регистрации" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Regs
