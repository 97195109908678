import React, { useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Input, Radio, Spin } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import cn from "classnames";
import _ from "lodash";

import {
  searchFilter,
  updateFilterItemInList,
} from "../../../../../../../../../helpers/utils/reports";
import { unApi } from "../../../../../../../api/endpoints/reports/unApi";
import { useActions } from "../../../../../../../hooks/useActions";
import { useForceUpdate } from "../../../../../../../hooks/useForceUpdate";
import { useTypedSelector } from "../../../../../../../hooks/useTypedSelector";
import ListTextSelector from "../../../ListTextSelector";

const ListPicker = ({
  disabled = false,
  id,
  index,
  modalVisibleHandler,
  prefix,
  ...props
}: any) => {
  const forceUpdate = useForceUpdate();

  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const filters = useTypedSelector(
    (state) => state.compare.compareFilters[`compareFilters${prefix}`]
  );
  const { setCompareFilter, setMetaFilterUpdate } = useActions();

  const [isEditing, setIsEditing] = useState(false);
  const [filterMode, setFilterMode] = useState(1);
  const [_id, _setId] = useState(id);

  useEffect(() => {
    forceUpdate();
  }, [props.itemData, index, id]);

  useEffect(() => {
    let _tempId = id;

    if (filterMode === 2) {
      setFilterMode(2);
      if (!id.includes("not_")) {
        _tempId = `not_${id}`;
      }
    } else {
      setFilterMode(1);
      if (id.includes("not_")) {
        _tempId = id.split("not_")[1];
      } else {
        _tempId = id;
      }
    }

    _setId(_tempId);
  }, [id, filterMode]);

  useEffect(() => {
    if (id.includes("not_")) {
      setFilterMode(2);
    } else {
      setFilterMode(1);
    }
  }, []);

  const filter = useMemo(() => {
    let _filter = props.itemData ? props.itemData : null;
    if (!props.list) {
      _filter = null;
    } else if (!isEditing) {
      setIsEditing(true);
    }

    return _filter ? _filter : { items: [], checkeds: [], ...props };
  }, [filters, _id, index, props.list, props.itemData, props]);

  const [items, setItems] = useState(filter.items);
  const [searchValue, setSearchValue] = useState("");

  const [currentData, setCurrentData] = useState(filter);

  const onApply = () => {
    let _currentData = {
      ...currentData,
      id: _id,
    };

    if (isEditing) {
      let _filters = _.cloneDeep(filters);

      const payload = { ..._currentData };
      _filters = updateFilterItemInList(_filters, index, payload);

      setCompareFilter({ index: prefix, filters: _filters });
      setMetaFilterUpdate({value:true});
      modalVisibleHandler(false);

      return;
    }

    const payload = {
      ..._currentData,
      id: _id,
    };

    let _filters = _.cloneDeep(filters);
    _filters.push(payload);

    setCompareFilter({ index: prefix, filters: _filters });
    setMetaFilterUpdate({value:true});
    modalVisibleHandler(false);
  };

  useEffect(() => {
    if (items.length !== 0 || !selectedSchool) return;

    unApi
      .getFilterList(parseInt(selectedSchool?.id || "0"), id)
      .then(function (response) {
        setItems(response.data);
      })
      .catch(function (error) {
        console.log("error = ", error);
      });
  }, [id, items.length]);

  const setCheckeds = (checkeds: any[]) => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        items,
        checkeds,
      };
    });
  };

  const onChangeCheck = (checkedValue: CheckboxValueType[]) => {
    const checkeds = items.filter(({ id }: any) => checkedValue.includes(id));

    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        items,
        checkeds,
      };
    });
  };

  const onChangeSearch = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const checkALl = () => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        checkeds: items.filter((item: any) => searchFilter(item, searchValue)),
      };
    });
  };

  const onClear = () => {
    setCurrentData((prevState: any) => {
      return {
        ...prevState,
        checkeds: [],
      };
    });
  };

  return (
    <div style={{ height: 380 }}>
      <Input
        disabled={disabled}
        onChange={onChangeSearch}
        onPressEnter={onChangeSearch}
        placeholder="Найти"
        allowClear
      />

      <div style={{ marginTop: 15 }}>
        <Radio.Group
          disabled={disabled}
          onChange={(e) => setFilterMode(e.target.value)}
          value={filterMode}
        >
          <Radio value={1}>Включить</Radio>
          <Radio value={2}>Исключить</Radio>
        </Radio.Group>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
        }}
      >
        <Button disabled={disabled} onClick={checkALl} size="small">
          Выбрать все
        </Button>

        <ListTextSelector id={id} list={items} setList={setCheckeds} />

        <Button disabled={disabled} onClick={onClear} size="small">
          Очистить
        </Button>
      </div>

      <div style={{ width: 300, height: 200, overflowY: "auto" }}>
        {items.length === 0 && (
          <div className="load-spin">
            <Spin spinning={true} tip="Загрузка..." />
          </div>
        )}

        <Checkbox.Group
          disabled={disabled}
          value={currentData.checkeds.map((item: any) => item.id)}
          onChange={onChangeCheck}
        >
          {items.map((item: any) => {
            const hide = !searchFilter(item, searchValue);
            return (
              <div
                key={item.id}
                className={cn("listpicker-item", {
                  "listpicker-item-hide": hide,
                })}
              >
                <Checkbox value={item.id} style={{ marginRight: 7 }}>
                  {item.name.replaceAll(/&nbsp;/g, "\u00A0")}
                </Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
      </div>

      <Button
        disabled={disabled}
        type="primary"
        style={{ marginTop: 10 }}
        onClick={onApply}
      >
        Применить
      </Button>
    </div>
  );
};

export default ListPicker;
