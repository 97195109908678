import { reportsSliceActions } from "./reportsSlice"
import { unApi } from "../../../../../../../shared/common/api/endpoints/reports/unApi"
import { handleResponseError } from "../../../../../../../shared/common/api/middleware"
import { showSuccessMessage } from "../../../../../../../helpers/utils/ui"
import { TAppDispatch } from "../../../../store"
import {tableSliceActions} from "../../table/tableSlice";

export const reportsActions = {
    ...reportsSliceActions,

    getReports: (schoolId: string | number) => async (dispatch: TAppDispatch) => {
        dispatch(reportsActions.setReportsLoading(true))
        let currentLocation = location.pathname
        unApi.getReports(schoolId,currentLocation)
            .then((res) => {
                if(location.pathname !== currentLocation){
                    return
                }
                dispatch(reportsActions.setReports(res.data))
            })
            .catch((err) => {
                console.log(err)
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(reportsActions.setReportsLoading(false))
            })
    },

    postReport: (schoolId: string | number, { values }: any) => async (dispatch: TAppDispatch) => {
        dispatch(reportsActions.setReportsLoading(true))

        unApi.createReport(schoolId, values,location.pathname)
            .then(() => {
                unApi.getReports(schoolId,location.pathname).then((res) => {
                    dispatch(reportsActions.setReports(res.data))
                    dispatch(reportsActions.setCurrentReport(res.data[res.data.length - 1]))

                    showSuccessMessage("Отчёт сохранен")
                })
                    .catch((err) => {
                        handleResponseError(dispatch, err)
                    })
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(reportsActions.setReportsLoading(false))
            })
    },
    getCreatedMetrics: (schoolId:number | string,pathname=location.pathname) => async (dispatch: TAppDispatch) => {
                unApi.getCustomMetrics(schoolId,pathname).then((res) => {
                    dispatch(tableSliceActions.setCreatedMetrics(res.data))
                })
                    .catch((err) => {
                        handleResponseError(dispatch, err)
                    })
    },
    putReport: (schoolId: string | number, { id, values }: any,currentReport?:any) => async (dispatch: TAppDispatch) => {
        dispatch(reportsActions.setReportsLoading(true))

        unApi.editReport(schoolId, id, values,location.pathname)
            .then(() => {
                unApi.getReports(schoolId,location.pathname).then((res) => {
                    dispatch(reportsActions.setReports(res.data))
                    const editedReport = res.data.find((report: any) => report.id === id)
                    if (editedReport.id === currentReport.id) {
                        dispatch(reportsActions.setCurrentReport(editedReport))
                    }
                    showSuccessMessage("Отчет пересохранен")
                })
                    .catch((err) => {
                        handleResponseError(dispatch, err)
                    })
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(reportsActions.setReportsLoading(false))
            })
    },

    deleteReport: (schoolId: string | number, { id }: any) => async (dispatch: TAppDispatch) => {
        dispatch(reportsActions.setReportsLoading(true))

        unApi.deleteReport(schoolId, id,location.pathname)
            .then((res) => {
                unApi.getReports(schoolId,location.pathname).then((res) => {
                    dispatch(reportsActions.setReports(res.data))
                    window.location.reload()

                    showSuccessMessage("Отчет удален")
                })
                    .catch((err) => {
                        handleResponseError(dispatch, err)
                    })
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(reportsActions.setReportsLoading(false))
            })
    },
}
