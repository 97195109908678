import {FC, useEffect, useState} from "react"

import { Space } from "antd"

import Attribution from "./attribution"
import Details from "./details"
import UniversalFilter from "./filter"
import CompareSection from "./filter/compare"
import CompareButton from "./filter/CompareButton"
import styles from "./Params.module.scss"
import PeriodSelector from "./period"
import { IAttributionItem, IDetailItem } from "../../../../../app/types/filter"
import { useTypedSelector } from "../../../hooks/useTypedSelector"


interface IParamsProps {
  withAttribution?: boolean;
  attributionItems?: IAttributionItem[];
  withDetails?: boolean;
  detailsItems?: IDetailItem[];
  withCompare?: boolean;
  withSegments?: boolean;
  filtersFilter?: string[];
  isFilterVisible?:boolean
}

const Params: FC<IParamsProps> = ({
    withAttribution = true,
    attributionItems,
    withDetails = true,
    detailsItems,
    withCompare = true,
    withSegments = true,
    filtersFilter,
    isFilterVisible=true
}) => {
    const { isCompare,isNotParamsFilterVisible } = useTypedSelector((state) => state.meta)


    return (
    <Space direction="vertical">
        { !isCompare && <PeriodSelector isCompare={isCompare} />}
      <Space direction="horizontal" style={{position:"relative"}} className={styles.params}>
        {withAttribution && attributionItems && (
          <Attribution items={attributionItems} />
        )}
        {withDetails && detailsItems && <Details items={detailsItems} />}
        {withCompare && <CompareButton isCompare={isCompare} />}
      </Space>
      <div className="filter-wrapper">
        {isCompare ? (
          <CompareSection filtersFilter={filtersFilter} />
        ) : (
             isFilterVisible && <UniversalFilter
              isCompare={isCompare}
              withSegment={withSegments}
              filtersFilter={filtersFilter}
          />
        )}
      </div>
    </Space>
    )
}

export default Params
