
import React, {FC, useEffect, useRef, useState} from "react"

import {CloseOutlined, DeleteOutlined, FilterOutlined, PlusOutlined} from "@ant-design/icons"
import {
    Button,
    Checkbox,
    Dropdown,
    Input,
    InputNumber,
    Menu,
    Modal,
    Popconfirm,
    Popover,
    Radio,
    Select,
    Space,
    Spin
} from "antd"
import TextArea from "antd/es/input/TextArea"
import { find } from "lodash"

import ConversationUI, {actionsWithN} from "./create-mertrics/ConversationUI"
import FillingConditions from "./create-mertrics/FillingConditions"
import InputWithColorPreview from "./create-mertrics/InputWithColorPreview"
import ModalSettings from "./create-metrics-modals/ModalSettings"
import Filter from "./params/filter"
import { EditMetric } from "../../../../app/types/unApi"
import {filterTree, findMetricById, findObjectById, getMetricFormula} from "../../../../helpers/utils/functions"
import {generateListKeys, prepareData} from "../../../../helpers/utils/reports"
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/utils/ui"
import { unApi } from "../../api/endpoints/reports/unApi"
import { dataItemsJsonUniversal, listKeysJsonUniversal } from "../../constants/metrics"
import { metrics as staticMetricsUniversal } from "../../constants/reports"
import { metrics as staticMetricsWebinar } from "../../constants/reportsWebinar"
import { metrics as staticMetricsAd } from "../../constants/reportsAD"
import { metrics as staticMetricsOrder } from "../../constants/reportsOrder"
import { metrics as staticMetricsPayment } from "../../constants/reportsPayment"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import {RadioChangeEvent} from "antd/es";
import {filtersDependsOnNamePath, filtersMap, termsForFilteringCustomMetrics} from "../../../../helpers/utils/data";
import {IFilter} from "../../../../app/providers/redux/slices/reports/params/filter/types";
import {getCookie} from "../../../../helpers/utils/cookies";

type Props = {
    visible: boolean;
    setEditState:React.Dispatch<React.SetStateAction<{visible:boolean,id:null | number,type:string | null}>>;
    id:number | null
    confirmCreatedMetricsRemoving:(nodeData:any)=>void
}
const staticMetrics = {
    '/users/webinar-reports':staticMetricsWebinar,
    '/users/universal':staticMetricsUniversal,
    '/users/order-reports':staticMetricsOrder,
    '/users/payment-reports':staticMetricsPayment,
    '/users/ad-reports':staticMetricsAd
}
enum Units {
    Quantity = "Количество",
    Currency = "Валюта",
    Percentage = "%"
}
enum FillingOption {
    Full = 'full',
    ValueBased = 'valueBased'
}

interface ModalState {
    name: string
    plan: string
    description: string
    whatWeCount?: number
    sum?: number
    conditionType: string
    conditionValue: null | number
    unit: Units | null
    isFilling: boolean
}
interface SelectedItem {
    namePath: string[]
}
const EditMetricsModal = ({ visible,setEditState,id,confirmCreatedMetricsRemoving }: Props) => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { getCreatedMetrics } = useActions()
    const [ isLoading,setisLoading ] = useState(false)
    const [ modalState, setModalState ] = useState<ModalState>({
        name: "",
        plan:"",
        description: "",
        whatWeCount: undefined,
        sum: undefined,
        conditionType: "equal",
        conditionValue: null,
        unit:Units.Quantity,
        isFilling:false
    })
    const { metrics,createdMetrics } = useTypedSelector((state) => state.table)
    const dataForDelete = useRef({})
    const [ fillingConditions, setFillingConditions ] = useState([
        { from: "", to: "", value: "#000000",id:1 },
    ])
    const [ converseData,setConverseData ] = useState<any>({
        selectedAction:{ id:"",label:"" },
        constant_argument:null,
    })
    const [ selected,setSelected ] = useState([])
    const { filters } = useTypedSelector((state) => state.filter)
    const [initialFilters,setInitialFilters] = useState<IFilter[]>([])
    const { setFilters } = useActions()
    useEffect(()=>{
        if(visible){
            setInitialFilters(filters)
            setFilters([])
        }
    },[visible])
    useEffect(()=>{
        return () => {
            setFilters(initialFilters)
        }
    },[initialFilters])
    const [ isConverse,setIsConverse ] = useState(false)
    const [ backgroundColor,setBackgroundColor ] = useState("#fffffff")

    const handleInputChange = (e:any) => {
        const { name, value } = e.target
        setModalState(prevState => ({ ...prevState, [name]: value }))
    }
    const addCondition = () => {
        if(fillingConditions.length === 10){
            showErrorMessage("Достигнут лимит по условиям 10 штук.")
            return
        }
        setFillingConditions((prevConditions) => [ ...prevConditions, { from: "", to: "", value: "#000000",id:prevConditions.length + 1 } ])
    }

    const handleFillingValueChange = (index:number, field:string, newValue:string) => {
        setFillingConditions((prevConditions) => {
            const updatedConditions = [ ...prevConditions ]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatedConditions[index][field] = newValue
            return updatedConditions
        })
    }
    const handleSubmit = async () => {
        if(selectedSchool?.id){
            try{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const baseMetricsName = selected[0]?.dataKey
                const dataKeysArray = isConverse ? Object.values(converseData.inputStates).map((item: any) => item.selected[0]?.dataKey) : []
                const metric_formula = isConverse ? getMetricFormula(converseData): null
                if(isConverse && dataKeysArray.length < 2 && !actionsWithN.includes(converseData.selectedAction.id)){
                    showErrorMessage("Выберите метрику")
                    return
                }
                if(!baseMetricsName && !isConverse){
                    showErrorMessage("Не выбрана базовая метрика")
                    return
                }
                if(modalState.name.trim().length === 0){
                    showErrorMessage("Введите название метрики")
                    return
                }
                const visual_parameters = JSON.stringify({
                    fillingConditions,
                    backgroundColor,
                    plan:modalState.plan,
                    isFilling:modalState.isFilling,
                    unit:modalState.unit,
                    ...(!isConverse && { base_metric_name: baseMetricsName }),
                    ...(isConverse && {metric_formula})
                })
                const values = {
                    id,
                    school_id:selectedSchool.id,
                    metric_type_id:isConverse ? 2 : 1,
                    custom_metric_name:modalState.name,
                    description:modalState.description || null,
                    base_metric_name: isConverse ? null : baseMetricsName,
                    filter_json:isConverse ? null : JSON.stringify(filters),
                    visual_parameters:visual_parameters,
                    metric_formula:metric_formula,
                    constant_argument:isConverse ? converseData.constant_argument : null
                }
                const response =  await unApi.editCustomMetric(values,location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname)
                if(response.status === 200){
                    setFilters([])
                    showSuccessMessage("Метрика успешнно обновлена")
                    setTimeout(()=>getCreatedMetrics(selectedSchool.id),150)
                    setEditState((prev)=>({ id:null,visible:false,type:"save" }))
                }
            }catch(err){
                console.log(err)
                showErrorMessage("Произошла ошибка во время обновления метрики.")
            }
        }

    }
    const handleDeleteCondition = (id:number) => {
        setFillingConditions((prevConditions) => prevConditions.filter((condition) => condition.id !== id))
    }

    const conversationMetricsData = prepareData(
        [ ...staticMetrics[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof staticMetrics], ...structuredClone(createdMetrics) ],
        "",
        [],
        false
    )?.filter((el:{name:string})=>el.name !== "Шаги воронки")
const handleDeleteMetric = async () => {
    confirmCreatedMetricsRemoving(dataForDelete.current)
}
useEffect(()=>{
    const getCustomMetricData = async () => {
        setisLoading(true)
        try{
            if(id !== null){
                const { data }  = await (unApi.getCustomMetric(id,location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as unknown as  {data:EditMetric})
                dataForDelete.current = { id:data.id,dataKey:data.id,name:data.custom_metric_name }
                setSelected([ findMetricById(JSON.parse(dataItemsJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof dataItemsJsonUniversal]),data.base_metric_name) ] as any)
                if(JSON.parse(data.visual_parameters)){
                    setBackgroundColor(JSON.parse(data.visual_parameters)?.backgroundColor)
                    setFillingConditions(JSON.parse(data.visual_parameters)?.fillingConditions)
                    setModalState((prev)=>({ ...prev,plan: JSON.parse(data.visual_parameters)?.plan || "",isFilling:JSON.parse(data.visual_parameters)?.isFilling || false,unit:JSON.parse(data.visual_parameters)?.unit || Units.Quantity }))
                }
                setIsConverse(data.metric_type_id === 2)
                if(data.metric_type_id === 2){
                    const metrics = data.metric_formula!.split("/").filter((el,index)=>index !== 0)
                    const inputStates = metrics.reduce((previousValue,currentValue,index)=>{
                        const metric = findMetricById(conversationMetricsData,currentValue)!
                        return  metric ? { ...previousValue,[`input${index + 1}`]:{
                                checkedKeys:[ metric.key ],
                                selected:[ metric ]
                            } } : previousValue
                    },{})
                    setConverseData({ constant_argument: actionsWithN.includes(data.metric_formula!.split("/")[0])? Number(data.constant_argument) : null,selectedAction:{ id:data.metric_formula!.split("/")[0],label:"" },inputStates })
                }
                data.filter_json ? setFilters(Array.isArray(JSON.parse(data.filter_json)) ? JSON.parse(data.filter_json) : [ JSON.parse(data.filter_json) ]) : null
                setModalState((prev)=>({ ...prev,name:data.custom_metric_name,description: data.description || "" }))
            }
        }catch(err){
            console.log(err)
        }finally {
            setisLoading(false)
        }
    }
    getCustomMetricData()
},[])
    const handleClearCondition = (index:number) => {
        setFillingConditions(currentConditions =>
            currentConditions.map((condition, i) =>
                i === index ? { ...condition, from: "", to: "", value: "#000000" } : condition
            )
        );
    };
    const filtersArray = React.useMemo(() => {
        const selectedTyped = selected as SelectedItem[]
        if (selectedTyped?.length > 0 && selectedTyped[0]?.namePath && selectedTyped[0]?.namePath?.length > 0) {
            const namePathKey = selectedTyped[0]?.namePath[0] as keyof typeof filtersDependsOnNamePath["/users/universal"];
            return filtersDependsOnNamePath[location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname]?.[namePathKey] || [];
        }
        return filtersMap[location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname] || []
    }, [location.pathname, selected])
    const listKeysCustomMetric = generateListKeys(structuredClone(createdMetrics))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <Modal style={{ top:20 }} width={"55%"} title={"Редактирование метрики"} onCancel={()=>setEditState((prev)=>({ id:null,visible:false,type:null }))} open={visible}
               footer={[
                   <div style={{ display: "flex", justifyContent: "space-between" }}>
                       <Button type={"primary"} onClick={handleSubmit} key="save">Сохранить</Button>
                       <Popconfirm
                           title="Удалить?"
                           onConfirm={()=>handleDeleteMetric()}
                           okText="Да"
                           cancelText="Нет"
                       >
                           <Button key="delete" style={{ marginLeft: "10px",border:"1px solid black" }}>Удалить метрику</Button>
                       </Popconfirm>
                   </div>
               ]}
        >
            {isLoading ? (
                <div style={{ position: "relative", minHeight: "200px" }}>
                    <Spin size="large" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </div>
            ) : (
                <>
                    <div style={{ display:"flex",columnGap:"20px" }}>
                        <div style={{ width:"65%" }}>
                            <div style={{ fontWeight:"700",marginBottom:"8px" }}>Название метрики</div>
                            <Input
                                name="name"
                                value={modalState.name}
                                maxLength={100}
                                style={{ width:"100%" }}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div style={{ width:"35%" }}>
                            <div style={{ fontWeight:"700",marginBottom:"8px" }}>Описание</div>
                            <TextArea
                                maxLength={300}
                                style={{ height: "32px",width:"100%" }}
                                name="description"
                                value={modalState.description}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div>
                        <div style={{ display:"flex",columnGap:"14px",marginTop:"5px" }}>
                            <div>
                                <Radio.Group
                                    value={isConverse}
                                    onChange={(e) => setIsConverse(e.target.value)}
                                >
                                    <Radio value={false}>Добавить фильтр к метрике</Radio>
                                    <Radio value={true}>Рассчитать метрику</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        {!isConverse && <div>
                            <ModalSettings selectedProps={selected} setSelectedProps={setSelected} dataItems={filterTree(JSON.parse(dataItemsJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof dataItemsJsonUniversal]),termsForFilteringCustomMetrics)} listKeys={JSON.parse(listKeysJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof listKeysJsonUniversal])} defSelected={[]} defChecked={selected.map((el:{key:string})=>el?.key || "")}  />

                        </div>}
                        {isConverse && <ConversationUI setConverseData={setConverseData} converseData={converseData} isEdit={true}  dataItems={conversationMetricsData} listKeys={[...JSON.parse(listKeysJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof listKeysJsonUniversal]),...listKeysCustomMetric]} defSelected={[]} defChecked={[]} />}

                        {!isConverse && (
                            <div style={{ marginTop:"10px" }}>
                                <Filter
                                    notParams={true}
                                    withSegment={false}
                                    filtersFilter={filtersArray}
                                    isCompare={false}
                                />
                            </div>
                        )}
                        <div style={{ display:"flex",alignItems:"center",columnGap:"40px",marginTop:"10px" }}>
                            <div style={{ display:"flex",columnGap:"40px" }}>
                                <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                                    <span style={{ fontWeight:"600" }}>Фон столбца:</span>
                                    <InputWithColorPreview value={backgroundColor} onChange={setBackgroundColor}  />
                                </div>
                            </div>
                            <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                                <span style={{ fontWeight:"600" }}>План</span>
                                <div style={{ display:"flex",alignItems:"center",columnGap:"5px" }}>
                                    <Input value={modalState.plan} onChange={(e)=>setModalState(prev=>({ ...prev,plan:e.target.value }))} style={{ width:"86px" }} />
                                    <span>на 1 день</span>
                                </div>
                            </div>
                            <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                                <span style={{ fontWeight:"600" }}>Единица измерения</span>
                                <div style={{ display:"flex",alignItems:"center",columnGap:"5px" }}>
                                    <Select
                                        defaultValue={modalState.unit}
                                        placeholder="Выберите единицу измерения"
                                        onChange={(value: Units) => {
                                            setModalState(prevState => ({
                                                ...prevState,
                                                unit: value
                                            }));
                                        }}
                                        style={{ width: 200 }}
                                    >
                                        {Object.entries(Units).map(([key, value]) => (
                                            <Select.Option key={key} value={key}>
                                                {value}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>

                                <div style={{ display:"flex",rowGap:"10px",flexDirection:"column",marginTop:"24px" }}>
                                    <span style={{ fontWeight:"700" }}>Условия заливки ячейки:</span>
                                    <Radio.Group onChange={(e: RadioChangeEvent) => {
                                        const fillingOption: FillingOption = e.target.value as FillingOption
                                        setModalState(prevState => ({
                                            ...prevState,
                                            isFilling: fillingOption === FillingOption.ValueBased
                                        }))
                                    }} value={modalState.isFilling ? 'valueBased' : 'full'}>
                                        <Space direction="vertical">
                                            <Radio value="full">Заливать всю ячейку</Radio>
                                            <Radio value="valueBased">Заливать ячейку от значения</Radio>
                                        </Space>
                                    </Radio.Group>
                                    {fillingConditions.map((condition, index) => (
                                        <FillingConditions
                                            key={condition.id}
                                            from={condition.from}
                                            to={condition.to}
                                            value={condition.value}
                                            id={condition.id}
                                            onClear={() => handleClearCondition(index)}
                                            onDelete={()=>handleDeleteCondition(condition.id)}
                                            onChange={(field, newValue) => handleFillingValueChange(index, field, newValue)}
                                        />
                                    ))}
                                </div>
                                <Space style={{ marginTop:"16px" }}>
                                    <span style={{ fontWeight:"600" }}>Добавить условие заливки</span>
                                    <Button onClick={addCondition} icon={<PlusOutlined />} type="primary" shape="circle" />
                                </Space>
                    </div>
                </>
            )}

        </Modal>
    )
}

export default EditMetricsModal
