import React, {FC, memo, useEffect, useMemo, useState} from "react"

import styles from "./Ad.module.scss"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"

import {useLocation, useNavigate} from 'react-router-dom';
import {placingAdOptions} from "./data";
import TabSelect from "../../../../shared/common/components/TabSelect/TabSelect"
import AddSpend from "../add-spend/AddSpend";
import {PagesPathnames} from "../../../../app/types/ad";
import ConnectAds from "../connect-ads";
import {getCurrentAdPage} from "../../../../helpers/utils/functions";
import {useActions} from "../../../../shared/common/hooks/useActions";


const Ad: FC = () => {
    const locationState = useLocation()

    const [ pageMode, setPageMode ] = useState(getCurrentAdPage(locationState.pathname))
    const { setFilters } = useActions()

    useEffect(() => {
        setFilters([])
    }, [pageMode]);

    useEffect(() => {
        setPageMode(getCurrentAdPage(locationState.pathname))
    }, [locationState.pathname])

    const currentPage = useMemo(() => {
        if(locationState.pathname === PagesPathnames.manualPlacement){
            return <AddSpend />
        }
        if(locationState.pathname === PagesPathnames.autoPlacement){
            return <ConnectAds />
        }
        return <></>
    },[locationState.pathname])





    return (
        <MainLayout pageTitle="Реклама">
            <div className="page-wrapper">
                <PageHead title="Реклама" />
                <div className="page-content">

                    <div className={styles.selectWrapper}>
                        <TabSelect options={placingAdOptions}  pageMode={pageMode} setPageMode={setPageMode}>
                        </TabSelect>


                    </div>
                    { currentPage }
                </div>

            </div>
        </MainLayout>
    )
}

export default Ad
