export const checkedDetailsList = [
  { name: "по дням", value: "d" },
  { name: "по неделям", value: "w" },
  { name: "по дням недели", value: "d-w" },
  { name: "по месяцам", value: "m" },
  { name: "по кварталам", value: "q" },
  { name: "по годам", value: "y" },
  { name: "1 минута", value: "1m" },
  { name: "5 минут", value: "5m" },
  { name: "15 минут", value: "15m" },
];

export const chartDetails = [
  { name: "по дням", value: "d" },
  { name: "по неделям", value: "w" },
  { name: "по дням недели", value: "d-w" },
  { name: "по месяцам", value: "m" },
  { name: "по кварталам", value: "q" },
  { name: "по годам", value: "y" },
];

export const webinarDetails = [
  { name: "1 минута", value: "1m" },
  { name: "5 минут", value: "5m" },
  { name: "15 минут", value: "15m" },
];

export const cogDetails = [
  { name: "по дням", value: "d" },
  { name: "по неделям", value: "w" },
  { name: "по месяцам", value: "m" },
  { name: "по кварталам", value: "q" },
  { name: "по годам", value: "y" },
];
