import React, { FC, useEffect, useState } from "react"

import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusOutlined, QuestionCircleOutlined
} from "@ant-design/icons"
import {Button, Modal, Space, Table, Tooltip} from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./AddModal"
import EditModal from "./EditModal"
import styles from "./Staff.module.scss"
import MainLayout from "../../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../../shared/common/components/page-header/PageHeader"
import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"


const Staff: FC = () => {

    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { isAboutReportOpen } = useTypedSelector((state) => state.app)

    const { isLoading, isUpdate, staffData } = useTypedSelector((state) => state.staff)
    const { staffGetData, staffSetUpdate, staffDeleteUser,setAppReportOpen,setDrawerLink } = useActions()

    const [ addModalVisible, setAddModalVisible ] = useState(false)
    const [ editModalVisible, setEditModalVisible ] = useState(false)
    const [ editUserId, setEditUserId ] = useState(0)

    useEffect(() => {
        if (selectedSchool) {
            staffGetData(selectedSchool.id)
        }
    }, [ selectedSchool ])

    useEffect(() => {
        if (selectedSchool) {
            staffGetData(selectedSchool.id)
            staffSetUpdate(false)
        }
    }, [ isUpdate ])

    const connectStagesBtn = (
    <span
        style={{
            marginLeft: 10,
            marginRight: 10,
            marginTop: 3,
            cursor:"pointer"
        }}
        onClick={() => {
            try{
                    setDrawerLink(`https://help.scananalytics.ru/sotrudniki?timestamp=${new Date().getTime()}`)
                    setAppReportOpen(true);
                }
            catch(err){
                console.log(err)
            }
        }}
    >
                        <QuestionCircleOutlined
                            style={{ color: "#ccc", fontSize: 14,marginTop:"8px",marginLeft:"-10px" }}
                        />
                  </span>
)
    const staffColumns: ColumnsType<any> = [
        {
            title: "Имя",
            dataIndex: "name",
            key: "name",
            width: "20%",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "30%",
        },
        {
            title: "Чем занимается",
            dataIndex: "role",
            key: "role",
            width: "20%",
        },
        {
            title: "UTM метки",
            dataIndex: "utm_labels",
            key: "utm_labels",
            render: (text, record) => (
                <>
                    <div>utm_source = {record.utm_source}</div>
                    <div>utm_medium = {record.utm_medium}</div>
                    <div>utm_campaign = {record.utm_campaign}</div>
                    <div>utm_term = {record.utm_term}</div>
                    <div>utm_content = {record.utm_content}</div>
                    <div>utm_group = {record.utm_group}</div>
                </>
            ),
            width: "24%",
        },
        {
            title: "Рекламные кабинеты",
            dataIndex: "ads",
            key: "ads",
            width: "20%",
        },
        {
            title: "Предложения",
            dataIndex: "offers",
            key: "offers",
            width: "15%",
        },
        {
            title: "Контакты пользователей",
            dataIndex: "contact",
            key: "contact",
            width: "20%",
        },
        {
            title: "Показывать настройки",
            dataIndex: "setting",
            key: "setting",
            width: "20%",
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: "15%",
            render: (value, record) => (
                <Space>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setEditUserId(record.id)
                            setEditModalVisible(true)
                        }}
                    />

                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            if (selectedSchool) {
                                Modal.confirm({
                                    title: "Вы уверены что хотите удалить сотрудника?",
                                    icon: <ExclamationCircleOutlined/>,
                                    okText: "Да",
                                    okType: "danger",
                                    cancelText: "Нет",
                                    onOk() {
                                        staffDeleteUser(parseInt(selectedSchool.id), record.id)
                                    },
                                })
                            }
                        }}
                        danger
                    />
                </Space>
            )
        },
    ]
    return (
        <MainLayout pageTitle="Сотрудники">
            <div className="page-wrapper">
                <PageHead
                    title="Сотрудники"
                    extraContent={connectStagesBtn}
                    extra={
                        <div>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setAddModalVisible(true)}
                                style={{ marginTop: 20 }}
                            >
                                Добавить сотрудника
                            </Button>
                        </div>
                    }
                />

                <div className="page-content">
                    <Table
                        className={styles.information_table}
                        size={"small"}
                        tableLayout={"fixed"}
                        columns={staffColumns}
                        dataSource={staffData}
                        loading={{
                            spinning: isLoading,
                            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }}
                        scroll={{ x: 2000 }}
                        bordered
                    />
                </div>

                <AddModal
                    isVisible={addModalVisible}
                    setVisible={setAddModalVisible}
                />

                <EditModal
                    isVisible={editModalVisible}
                    setVisible={setEditModalVisible}
                    userId={editUserId}
                />
            </div>
        </MainLayout>
    )
}

export default Staff
