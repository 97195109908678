import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IDetailsState } from "./types"
import { updateQueryParams} from "../../../../../../../helpers/utils/functions";


const defaultState:IDetailsState = {
    details: "d",
    detailsDashboardBlocks:{}
}
const getInitialState = () => {
    const url = new URL(window.location.href)
    const currentParams = url.searchParams.get('params')
    const paramsObject = currentParams ? JSON.parse(currentParams) : {}
    const details = paramsObject?.details
    const location = localStorage.getItem("location") || ""

    if(location?.length > 0 && location !== window.location.pathname){
        return defaultState
    }
    if (details) {
        return {...defaultState,details}
    }
    return defaultState
}
export const initialDetailsState = getInitialState()

export const detailSlice = createSlice({
    name: "detail",
    initialState: initialDetailsState,
    reducers: {
        setDetails: (state, action: PayloadAction<string>) => {
            state.details = action.payload
             updateQueryParams(action.payload,"details")
        },
        setDashboardBlockDetails:(state,action:PayloadAction<{blockId:string,detail:string}>) => {
            state.detailsDashboardBlocks[action.payload.blockId] = action.payload.detail
        }
    }
})

export const detailSliceReducer = detailSlice.reducer
export const detailSliceActions = detailSlice.actions
