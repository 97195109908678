import React, { FC, useEffect, useState } from "react";
import {Alert, PageHeader, Space, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {unApi} from "../../api/endpoints/reports/unApi";
import {useActions} from "../../hooks/useActions";

interface IPageHeaderProps {
    title: string | Element;
    description?: string;
    extra?: React.ReactNode;
    extraContent?:React.ReactNode
    reportInfo?:boolean
}

const PageHead: FC<IPageHeaderProps> = ({ title, description, extra,reportInfo,extraContent }) => {
    const { selectedSchool } = useTypedSelector((state)=>state.currentSchool)
    const { isAboutReportOpen } = useTypedSelector((state) => state.app);
    const { currentReport,reports } = useTypedSelector((state)=>state.reports)
    const [searchValue, setSearchValue] = useState<boolean>(false);
    const { setAppReportOpen,setDrawerLink } = useActions();
    useEffect(() => {
        if (selectedSchool?.id && Object.values(currentReport).length > 0) {
            location.pathname !== "/dashboards" && unApi.getReports(selectedSchool.id,location.pathname).then((response)=>{
                const isExistReport = response.data?.some((el:{id:number})=>el.id === currentReport?.id)
                const urlParams = new URLSearchParams(window.location.search);
                const newValue = urlParams.get("report_id");
                newValue && !isExistReport ?  setSearchValue(true) : setSearchValue(false)
            })
        }


    }, [reports,currentReport]);
    return (
        <>
            {localStorage.getItem("access_admin") && <Alert style={{fontSize:"16px"}} message="Вход произвиден под пользователем" type="error" />}
            <PageHeader
                title={title}
                subTitle={
                    <Space>
                        {description && (
                            <Tooltip
                                placement="bottom"
                                title={
                                    <div
                                        className="page-title-tooltip-text"
                                        dangerouslySetInnerHTML={{ __html: description }}
                                    ></div>
                                }
                                color="#fff"
                                zIndex={9999}
                                trigger="click"
                                overlayClassName="page-title-tooltip"
                            >
                                <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
                            </Tooltip>
                        )}
                        {reportInfo && (
                            <span
                                style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginTop: 3,
                                }}
                                onClick={(e) => {
                                    try{
                                        const event = e.target  as unknown as { innerText:string}
                                        if (event.innerText === "Подробнее об отчёте") {
                                            setAppReportOpen(true);
                                            setDrawerLink(`https://help.scananalytics.ru/moi-otchyoty?timestamp=${new Date().getTime()}`)
                                        }
                                        if(event.innerText === "здесь"){
                                            setAppReportOpen(true);
                                            setDrawerLink(`https://help.scananalytics.ru/?p=462&timestamp=${new Date().getTime()}`)
                                        }
                                    }catch(err){
                                        console.log(err)
                                    }
                                }}
                            >
                    {!isAboutReportOpen &&  <Tooltip
                        placement="bottom"
                        title={
                            <div
                                className="page-title-tooltip-text"
                                dangerouslySetInnerHTML={{
                                    __html: `Это самый основной отчёт в сервисе SCAN, который позволяет увидеть эффективность воронки в разных разрезах регистраций и с разными условиями и сегментами пользователей.
 <br> <a rel="noopener noreferrer" target="_blank">Подробнее об отчёте</a>. <br><br>
 Как создавать, сохранять и работать с сотнями отчётов описано <a rel="noopener noreferrer" target="_blank">здесь</a>.
`,
                                }}
                            ></div>
                        }
                        color="#fff"
                        zIndex={9999}
                        trigger="click"
                        overlayClassName="page-title-tooltip"
                    >
                        <QuestionCircleOutlined
                            style={{ color: "#ccc", fontSize: 14,marginTop:"8px",marginLeft:"-10px" }}
                        />
                    </Tooltip>}
                  </span>
                        )}
                        {extraContent}
                        {searchValue && (
                            <div
                                style={{
                                    border: "1px solid red",
                                    padding: "4px",
                                    color: "red",
                                }}
                            >
                                Отчёт открыт по ссылке. Сохраните его, чтобы работать с ним.
                            </div>
                        )}
                    </Space>
                }
                style={{ paddingBottom: "0" }}
                extra={extra || null}
            />
        </>

    );
};

export default PageHead;

