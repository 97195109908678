import React, {FC, useEffect} from "react"

import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon"
import Params from "../../shared/common/components/reports/params/Params"
import Chart from "../../shared/common/components/reports/web-chart/Chart"
import Table from "../../shared/common/components/reports/web-table/Table"
import UniversalReportTitle from "../../shared/common/components/reports/un-title/UniversalReportTitle"
import { chartDetails } from "../../shared/common/constants/details"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import PageHead from "../../shared/common/components/page-header/PageHeader";
import {listKeysAdminGroupings, listKeysGroupings} from "../../shared/common/components/reports/GroupingsWebinar";
import {listKeysMetrics} from "../../shared/common/components/reports/MetricsWebinar";
import {ToggleChart} from "../../widgets/ToggleChart";
import usePermission from "../../shared/common/hooks/usePermission";

export const defaultGroupingsWebinar = [ // Дефолтные группировки
    "reg_utm_source_4",
    "reg_utm_medium_4",
    "reg_utm_campaign_4",
    "web_duration_4",
    "web_name_4"
].map((dataKey) => listKeysGroupings.find((item: any) => item.dataKey === dataKey))
const PaymentReports: FC = () => {
    const checkPermission = usePermission()
    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"
    },[])
    const defaultGroupings = [ // Дефолтные группировки
        "reg_utm_source_4",
        "reg_utm_medium_4",
        "reg_utm_campaign_4",
         "web_duration_4",
         "web_name_4"
    ].map((dataKey) =>checkPermission(['superadmin']) ? listKeysAdminGroupings.find((item: any) => item.dataKey === dataKey) : listKeysGroupings.find((item: any) => item.dataKey === dataKey))
     const defaultMetrics = [ // Дефолтные метрики
         "web_users_count_4",
         "web_15min_users_count_4",
         "web_60min_g_users_count_4",
         "web_15min_users_part_4",
         "web_60min_g_users_part_4",
         "web_clickers_count_4",
         "clicker_to_creator_converse_4",
         "web_user_to_creator_converse_4",
         "orders_count_4",
         "orders_creators_count_4",
         "orders_sum_4",
         "orders_sum_avg_web_4",
         "payments_count_4",
         "buyers_count_4",
         "payments_sum_4",
         "web_to_buyer_converse_4",
         "payments_sum_per_web_4",
         "creator_to_buyer_converse_4"
     ].map((dataKey) =>
        listKeysMetrics.find((item: any) => item.dataKey === dataKey))
    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )
    const {
        setFilters,
        setFiltersTable,
        setCurrentReport,
        setMetrics,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setGroupings,
        setMetaChartFetching,
        setMetricsChecked,
        setCurrentFunnel
    } = useActions()
    const attrItems = [
        {
            id: "type_applications",
            name: "Тип регистрации:",
            selectValues: [
                { name: "Нулевые заказы GC", id: "2", icon: null },
                { name: "Регистрации GC", id: "0", icon: null },
                { name: "SCAN", id: "1", icon: null },
                //{ name: "Ручное добавление", id: "3", icon: null },
            ],
            description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: <TypeIcon />,
        },
        {
            id: "attribution",
            name: "Атрибуция:",
            selectValues: [
                { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
                { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
            ],
            description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
            icon: null,
        },
    ]


    useEffect(() => {
        setMetaCompare({value:false})
        setMetrics({data:defaultMetrics})
        setGroupings({data:defaultGroupings})
        //setFilters([])
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setTimeout(()=>{
            setMetaChartFetching({value:true})
        },700)
        setMetricsChecked({data:[{label:defaultMetrics[0].label,name:defaultMetrics[0].dataKey}]})
        return () => {
            setMetaCompare({value:false})
            setMetrics({data:defaultMetrics})
            setGroupings({data:defaultGroupings})
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentSegment(null)
            setCurrentFunnel([])
        }
    }, [])
    return (
    <MainLayout pageTitle="Отчёт по вебинарам">
      <div className="page-wrapper">
          <PageHead
              title="Отчёт по вебинарам"
              description={""}
              reportInfo={true}
          />
        <div className="page-content">
          <Loader loading={isTableLoading || isChartLoading}>
            <UniversalReportTitle />

            <Params
                attributionItems={attrItems}
                detailsItems={chartDetails}
                filtersFilter={[
                    "Регистрации",
                    "Пользователи",
                    "Заказы",
                    "Платежи",
                    "Вебинары",
                ]}
            />
          </Loader>
            <Loader loading={isTableLoading || isChartLoading}>
                {!isChartVisible &&  <ToggleChart />}
            </Loader>
            {isChartVisible && <Chart />}
          <Table />
        </div>
      </div>
    </MainLayout>
    )
}

export default PaymentReports
