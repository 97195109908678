import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IDashboardState} from "./types"

const initialState: IDashboardState = {
   dashboards:[]
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setDashboards: (state, action: PayloadAction<any[]>) => {
            state.dashboards = action.payload
        },
    },
})

export const dashboardSliceReducer = dashboardSlice.reducer
export const dashboardSliceActions = dashboardSlice.actions
