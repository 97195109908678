import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IMetaState } from "./types"

const initialState: IMetaState = {
    isChartFetching: false,
    isTableFetching: false,
    isChartLoading: false,
    isTableLoading: false,
    isParamsLoading: false,
    isAllExpandedLoading:false,
    isCompare: false,
    isChartCompareFetching: false,
    isTableCompareFetching: false,
    isFilterUpdate: false,
    currency: "",
    isNotParamsFilterVisible:false,
    blocks: {}
}
const initialStateBlock = {
    isChartFetching: true,
    isTableFetching: true,
    isChartLoading: true,
    isTableLoading: true,
    isParamsLoading: false,
    isAllExpandedLoading:false,
    isCompare: false,
    isChartCompareFetching: false,
    isTableCompareFetching: false,
    isFilterUpdate: false,
    currency: "",
    isNotParamsFilterVisible:false,
}

export const metaSlice = createSlice({
    name: "meta",
    initialState: initialState,
    reducers: {
        setDashboardsBlocksInitialState:(state,action: PayloadAction<string[]>) => {
            const newBlocksValues:{[key:string]:Omit<IMetaState,'blocks'>} = {}
            const ids = action.payload
            ids.forEach((el)=>{
                newBlocksValues[el] = initialStateBlock
            })
            state.blocks = {...state.blocks,...newBlocksValues}
        },
        setMetaChartFetching: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isChartFetching = value;
            } else {
                state.isChartFetching = value;
            }
        },
        setMetaTableFetching: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isTableFetching = value;
            } else {
                state.isTableFetching = value;
            }
        },
        setAllDataFetching: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isChartFetching = value;
                state.blocks[blockId].isTableFetching = value;
            } else {
                state.isChartFetching = value;
                state.isTableFetching = value;
            }
        },
        setMetaChartLoading: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isChartLoading = value;
            } else {
                state.isChartLoading = value;
            }
        },
        setMetaTableLoading: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isTableLoading = value;
            } else {
                state.isTableLoading = value;
            }
        },
        setIsAllExpandedLoading: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isAllExpandedLoading = value;
            } else {
                state.isAllExpandedLoading = value;
            }
        },
        setMetaParamsLoading: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isParamsLoading = value;
            } else {
                state.isParamsLoading = value;
            }
        },
        setMetaCompare: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isCompare = value
                state.blocks[blockId].isChartFetching = true
                state.blocks[blockId].isChartCompareFetching = true
                state.blocks[blockId].isTableFetching = true
                state.blocks[blockId].isTableCompareFetching = true
            }else{
                state.isCompare = action.payload.value
                state.isChartFetching = true
                state.isChartCompareFetching = true
                state.isTableFetching = true
                state.isTableCompareFetching = true
            }
        },
        setMetaCompareChartFetching: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isChartCompareFetching = value;
            } else {
                state.isChartCompareFetching = value;
            }
        },
        setMetaCompareTableFetching: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isTableCompareFetching = value;
            } else {
                state.isTableCompareFetching = value;
            }
        },
        setMetaFilterUpdate: (state, action: PayloadAction<{ value: boolean, blockId?: string }>) => {
            const { value, blockId } = action.payload;
            if (blockId) {
                state.blocks[blockId].isFilterUpdate = value
            } else {
                state.isFilterUpdate = value
            }
        },
        setMetaCurrency: (state, action: PayloadAction<string>) => {
            state.currency = action.payload;
        },
        setIsNotParamsFilterVisible: (state, action: PayloadAction<boolean>) => {
            state.isNotParamsFilterVisible = action.payload;
        }
    }
})

export const metaSliceReducer = metaSlice.reducer
export const metaSliceActions = metaSlice.actions
