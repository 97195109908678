import React, { FC, useEffect, useState } from "react"

import { Space } from "antd"

import ManualSelect from "./selects/ManualSelect"
import { IAttributionItem } from "../../../../../app/types/filter"

interface IAttributionProps {
    items: IAttributionItem[]
}

const Attribution: FC<IAttributionProps> = ({ items }) => {
    return (
        <Space className="params__attr-container">
            {
                items.map((item, index) => (
                    <ManualSelect
                        key={index}
                        id={item.id}
                        name={item.name}
                        selectValues={item.selectValues}
                        description={item.description}
                        icon={item.icon}
                    />
                ))
            }
        </Space>
    )
}

export default Attribution
