import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IChartData, IChartState } from "./types";
import {updateQueryParams} from "../../../../../../helpers/utils/functions";

const initialChartVisible = () => {
  const storageValue = localStorage.getItem("isChartVisible")
  if(storageValue){
    return storageValue === "true"
  }

  return true
}
const initialState: IChartState = {
  chartType: "line",
  series: [],
  labels: [],
  cumulativeChart: false,
  isChartVisible:initialChartVisible(),
  blocks:{}
};
const initialBlockState = {
  chartType: "line",
  series: [],
  labels: [],
  cumulativeChart: false,
  isChartVisible:initialChartVisible(),
}
export const chartSlice = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {
    setDashboardsBlocksChartState:(state,action: PayloadAction<{ids:string[],data?:Partial<Omit<IChartState,'blocks'>>}>) => {
      const {ids,data} = action.payload

      const newBlocksValues:{[key:string]:Omit<IChartState,'blocks'>} = {}
      ids.forEach((el)=>{
        newBlocksValues[el] = initialBlockState
      })
      if(data){
        newBlocksValues[ids[0]] = {...newBlocksValues[ids[0]],...data}
      }
      state.blocks = {...state.blocks,...newBlocksValues}
    },
    setChartVisible:(state) => {
      state.isChartVisible = !state.isChartVisible
    },
    setChartType: (state, action: PayloadAction<string | { data:string,blockId:string }>) => {
      const isString = typeof action.payload !== "object"
      if(isString){
        state.chartType = action.payload as string
      }else{
        state.blocks[(action.payload as { data:string,blockId:string }).blockId].chartType = (action.payload as { data:string,blockId:string }).data
      }
      updateQueryParams(action.payload,"chartType")
    },
    setSeries: (state, action: PayloadAction<IChartData[] | { data:IChartData[],blockId:string }>) => {
      const isArray = Array.isArray(action.payload)
      const newSeries = isArray ? structuredClone(action.payload) : structuredClone((action.payload as { data:IChartData[],blockId:string }).data)
      try{
        newSeries.forEach((el:{group:string})=>{
          el?.group ? el.group = el?.group.replace(/<span[^>]*>.*?<\/span>/gi, '') : null
        })
      }catch(err){
        console.log(err)
      }
      if(isArray){
        state.series = newSeries
      }else{
        state.blocks[(action.payload as { data:IChartData[],blockId:string }).blockId].series = (action.payload as { data:IChartData[],blockId:string }).data
      }
    },
    setLabels: (state, action: PayloadAction<IChartData[] | { data:IChartData[],blockId:string }>) => {
      const isArray = Array.isArray(action.payload)
      if(isArray){
        state.labels = action.payload as IChartData[]
      }else{
        state.blocks[(action.payload as { data:IChartData[],blockId:string }).blockId].labels = (action.payload as { data:IChartData[],blockId:string }).data
      }
    },
    setCumulativeChart: (state, action: PayloadAction<boolean | { data:boolean,blockId:string }>) => {
      updateQueryParams(action.payload,"cumulativeChart")
      const isString = typeof action.payload !== "object"
      if(isString){
        state.cumulativeChart = action.payload as boolean
      }else{
        state.blocks[(action.payload as { data:boolean,blockId:string }).blockId].cumulativeChart = (action.payload as { data:boolean,blockId:string }).data
      }
    },
  },
});

export const chartSliceActions = chartSlice.actions;
export const chartSliceReducer = chartSlice.reducer;
