import React, {FC, useEffect, useRef, useState} from "react"

import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import Params from "../../shared/common/components/reports/params/Params"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"
import { defaultGroupings, defaultMetrics } from "../../app/providers/redux/slices/reports/table/tableSlice"
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './Dashboards.module.scss'
import UniversalReportTitle from "../../shared/common/components/reports/dashboards/title/UniversalReportTitle"
import AddBlockModal from "./components/modal/AddBlockModal";
import Table from "../../shared/common/components/reports/dashboards/Table";
import Card from "./components/Card";
import Heading from "./components/Heading";
import {Button, Dropdown, Menu, Space, Switch, Tooltip} from "antd";
import {ArrowsAltOutlined, EllipsisOutlined, QuestionCircleOutlined} from "@ant-design/icons";
const ResponsiveGridLayout = WidthProvider(Responsive);
import Chart from "../../shared/common/components/reports/dashboards/Chart";
import EditBlockModal from "./components/modal/EditBlockModal";
import useResizeHandler from "../../shared/common/hooks/useResizeObserver";
interface GridItemLayout {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    minW?: number;
    minH?: number;
    maxW?: number;
    maxH?: number;
}
export interface DashboardComponent {
    title?: string
    componentType: string
    layoutData: GridItemLayout
    data: any
}


interface DashboardProps {
    isChartVisible: boolean;
}


const Dashboards: FC = () => {
    const [ addBlockVisible,setAddBlockVisible ] = useState({
        visible:false,
        blockType:""
    })
    const [ editBlockState,setEditBlockState ] = useState({
        visible:false,
        id:"",
        data:{}
    })
    const [isEditMode,setEditMode ] = useState(false)
    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"
    },[])
    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )
    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    const [isUpdated,setIsUpdated] = useState(false)
    const {
        setFilters,
        setMetrics,
        setGroupings,
        setFiltersTable,
        setCurrentReport,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setSeries,
        setMetricsChecked,
        setMetaChartFetching,
        setAttributesDashboardBlocks,
        setDashboardBlockDetails,
        setDashboardsBlocksInitialState,
        setDashboardsBlocksTableInitialState,
        setCurrentFunnel,
        setDashboards
    } = useActions()
    useEffect(() => {
        setMetaCompare({value:false})
        setMetrics({data:defaultMetrics})
        setGroupings({data:defaultGroupings})
        setFilters([])
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setSeries([])
        setTimeout(()=>{
            setMetaChartFetching({value:true})
        },700)
        setMetricsChecked({data:[{label:defaultMetrics[0].label,name:defaultMetrics[0].dataKey}]})

        return () => {
            setMetaCompare({value:false})
            setMetrics({data:defaultMetrics})
            setGroupings({data:defaultGroupings})
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentFunnel([])
            setCurrentSegment(null)
            setMetricsChecked({data:[]})
        }
    }, [])





    const [dashboardComponents,setDashboardComponents] = useState<DashboardComponent[]>([
        {
            title: "Компонент таблицы",
            componentType: "table",
            layoutData: {
                i: '1',
                x: 0,
                y: 25,
                w: 12,
                h: 10,
                minW: 3,
                minH: 4,
            },
            data: {
                blockId:"1",
                description:"test",
                date:"reg",
                attribution:{
                    attribution:["1"],
                    type_applications:["1"]
                },
                selectedMetrics:[],
                selectedGroupings:[],
                details:"m"
            }
        },
        {
            title: "Компонент таблицы 2",
            componentType: "table",
            layoutData: {
                i: '2',
                x: 3,
                y: 0,
                w: 12,
                h: 10,
                minW: 3,
                minH: 4,
            },
            data: {
                blockId:"2",
                description:"test",
                date:"reg",
                attribution:{
                    attribution:["2"],
                    type_applications:["1"]
                },
                selectedMetrics:[],
                selectedGroupings:[],
                details:"d"
            }
        },
    ])

    useEffect(() => {
        const dashboardComponentsIds: string[] = dashboardComponents.map((el)=>el.data.blockId)
        setDashboardsBlocksTableInitialState({ids:dashboardComponentsIds})
        setDashboardsBlocksInitialState(dashboardComponentsIds)
        dashboardComponents.forEach((component, index) => {
            const { attribution, details,blockId } = component.data;
            setAttributesDashboardBlocks({
                blockId,
                attribution: attribution?.attribution,
                type_applications: attribution?.type_applications
            })
            setDashboardBlockDetails({
                blockId,
                detail: details
            })
        })
        setIsUpdated(true)
    }, [])
    const layout = dashboardComponents.map(component => ({
        ...component.layoutData
    }))

    const onLayoutChange = (newLayout: GridItemLayout[]) => {
        const updatedComponents = dashboardComponents.map(component => {
            const layoutItem = newLayout.find(item => item.i === component.layoutData.i)
            return layoutItem ? { ...component, layoutData: layoutItem } : component
        })

        setDashboardComponents(updatedComponents)
    }
    useEffect(()=>{
        setDashboards(dashboardComponents)
    },[dashboardComponents])
    const handleAddBlock = (newComp:DashboardComponent) => {
        setDashboardComponents((prev)=>[...prev,newComp])
    }
    const handleEditBlock = (updComp:DashboardComponent) => {
        setDashboardComponents((prevState)=>{
            const updCompIndex = prevState.findIndex((comp)=>comp.data.blockId === updComp.data.blockId)
            if(updCompIndex){
                prevState[updCompIndex] = updComp
            }
            console.log(prevState,updCompIndex)
            return prevState
        })
    }
    console.log(dashboardComponents)
    const handleDelete = (id:string) => {
        setDashboardComponents(dashboardComponents.filter(component => component.layoutData.i !== id));
    };
    const handleEdit = (id:string) => {
        setEditBlockState( {
            id,
            visible: true,
            data: dashboardComponents.find((component) => component.layoutData.i === id) || {}
        })
    }


    const menu = (component:any) => (
        <Menu>
            <Menu.Item key="edit" onClick={()=>handleEdit(component.layoutData.i)}>
                <Button type="text">Редактировать</Button>
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(component.layoutData.i)}>
                <Button type="text">Удалить</Button>
            </Menu.Item>
        </Menu>
    );


    return (
    <MainLayout pageTitle="Дашборды">
        <div className="page-wrapper" style={{}}>
            <PageHead
                title="Дашборды"
                description={""}
                reportInfo={true}
            />
            <div className="page-content">
                <Loader loading={isTableLoading || isChartLoading}>
                    <UniversalReportTitle setAddBlockVisible={setAddBlockVisible}/>
                    <Params
                        withAttribution={false}
                        withCompare={false}
                        withDetails={false}
                        withSegments={false}
                        isFilterVisible={false}
                    />
                </Loader>
            </div>
                <div className="page-content" style={{background: "rgb(243, 243, 243)",borderTop:"1px solid #f2f2f2"}}>
                    <ResponsiveGridLayout
                        className="layout"
                        layouts={{lg: layout}}
                        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                        rowHeight={45}
                        onLayoutChange={(layout) => onLayoutChange(layout)}
                        isDraggable={true}
                        isResizable={true}
                        draggableHandle={`.${styles.dragIcon}`}
                        resizeHandle={<div
                            className={`${styles.resizeHandle}`}
                        >
                            <ArrowsAltOutlined rotate={90}/>
                        </div>}

                    >
                        {
                            isUpdated && dashboardComponents.map((component) => {
                                let ComponentToRender;

                                switch (component.componentType) {
                                    case "table":
                                        ComponentToRender = Table
                                        break;
                                    case "number":
                                        ComponentToRender = Card
                                        break;
                                    case "chart":
                                        ComponentToRender = Chart
                                        break;
                                    case "heading":
                                        ComponentToRender = () => <></>
                                        break;
                                    default:
                                        ComponentToRender = null
                                }

                                return ComponentToRender ? (
                                    <div
                                        onMouseEnter={()=>setEditMode(true)}
                                        onMouseLeave={()=>setEditMode(false)}
                                        className={`${styles.componentContainer} ${component.componentType !== "heading" ? styles.smoothComponent : styles.heading} ${component.componentType === "chart" ? "chartComponent" : ""}`}
                                        key={component.layoutData.i}>
                                        <div
                                            className={`${styles.dragIcon} drag-handler`}
                                        >
                                            <EllipsisOutlined/>
                                        </div>
                                        <div style={{display: "flex", alignItems: "center", columnGap: "8px"}}>
                                            <h2 style={{...(component.componentType === "heading" && {fontWeight: 800})}}>{component.title}</h2>
                                            {component.data?.description && (
                                                <Tooltip
                                                    placement="bottom"
                                                    title={
                                                        <div
                                                            className="page-title-tooltip-text"
                                                            dangerouslySetInnerHTML={{__html: component.data.description}}
                                                        ></div>
                                                    }
                                                    color="#fff"
                                                    zIndex={9999}
                                                    trigger="click"
                                                    overlayClassName="page-title-tooltip"
                                                >
                                                    <QuestionCircleOutlined
                                                        style={{color: "#ccc", fontSize: 14, marginTop: -5}}/>
                                                </Tooltip>
                                            )}
                                            <div className={styles.dropdownContainer}>
                                                <Dropdown overlay={menu(component)} trigger={['click']}>
                                                    <Button type="text" icon={<EllipsisOutlined/>}/>
                                                </Dropdown>
                                            </div>
                                        </div>

                                        <ComponentToRender {...component.data} isShow={true}/>
                                    </div>
                                ) : null
                            })
                        }
                    </ResponsiveGridLayout>

                    {addBlockVisible.visible &&
                        <AddBlockModal blockType={addBlockVisible.blockType} visible={addBlockVisible.visible}
                                       setVisible={setAddBlockVisible} onAddBlock={handleAddBlock}/>}
                    {editBlockState.visible &&
                        <EditBlockModal editState={editBlockState} setEditState={setEditBlockState}
                                        onEditBlock={handleEditBlock}/>}
                </div>
            </div>
    </MainLayout>
)
}

export default Dashboards
