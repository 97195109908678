import React, { FC } from "react"

import { API_REPORT_TABLE } from "../../../shared/common/api/urls"
import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import TableReport from "../../../shared/common/components/reports/table-report/TableReport"

const Orders: FC = () => {

    const metrics = [
        { name: "Номер заказа", dataKey: "order_number" },
        { name: "Дата и время заказа", dataKey: "order_timestamp" },
        { name: "id пользователя", dataKey: "id" },
        { name: "Имя Фамилия клиента", dataKey: "name" },
        { name: "E-mail", dataKey: "email" },
        { name: "Телефон", dataKey: "phone" },
        { name: "Сумма", dataKey: "amount" },
        { name: "Предложения", dataKey: "order_offers" },
        { name: "Статус", dataKey: "order_status" },
        { name: "Менеджер", dataKey: "manager_name" },
        { name: "utm_source заказа", dataKey: "utm_source_value" },
        { name: "utm_medium заказа", dataKey: "utm_medium_value" },
        { name: "utm_campaign заказа", dataKey: "utm_campaign_value" },
        { name: "utm_content заказа", dataKey: "utm_content_value" },
        { name: "utm_term заказа", dataKey: "utm_term_value" },
        { name: "utm_group заказа", dataKey: "utm_group_value" },
        { name: "Комиссия", dataKey: "pay_commission" },
        { name: "Оплачено", dataKey: "payed_amount" },
        { name: "Теги предложений", dataKey: "order_tags" },
        { name: "Теги заказов", dataKey: "order_tags_3" },
        { name: "Давность регистрации клиента", dataKey: "reg_age" },
        { name: "Клиент денег принес", dataKey: "pay_sum" },
        { name: "id хита", dataKey: "hit_id" },
    ]

    return (
        <MainLayout pageTitle="Заказы">
            <div className="page-wrapper">
                <PageHead
                    title="Заказы"
                    description={`
                        Отчёт, который позволяет посмотреть и выгрузить нужные заказы пользователей онлайн-школы. Подробнее.  
                    `}
                />

                <div className="page-content">
                    <TableReport
                        apiUrl={API_REPORT_TABLE}
                        metrics={metrics}
                        filtersFilter={[ "Пользователи", "Заказы" ]}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default Orders
