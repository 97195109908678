import React, {useRef, useState} from "react";
import {Popconfirm, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, QuestionCircleOutlined, SettingOutlined} from "@ant-design/icons";
type Props = {
    nodeData:any
    confirmCreatedMetricsRemoving:any
    handleEditMetric:(nodeData:any)=>void
}
const MetricItem = ({nodeData,confirmCreatedMetricsRemoving,handleEditMetric}:Props) => {
    const [ isHovered, setIsHovered ] = useState(false)
    const isBlocked = useRef(false)
    const handleMouseLeave = () => {
        !isBlocked.current &&   setIsHovered(false)
    }

    const bgColor = nodeData?.background ? nodeData.background.slice(0, 7) : "unset"
    const handleRemoveMetric = () => {
        confirmCreatedMetricsRemoving(nodeData)
        isBlocked.current = false
    }
    const handleRemoveMetricOpenChange = (open:boolean) => {
        isBlocked.current = open
        setIsHovered(open)
    }
    return (
        <span
            className="group-item-icon-wrapper"
            onMouseEnter={() => setIsHovered(true)}
            style={{ display:"flex",background:bgColor,color:"black",paddingLeft:"10px",justifyContent:"space-between" }}
            onMouseLeave={handleMouseLeave}
        >
            <span>
                 {nodeData.title}
                {
                    nodeData.description &&  <span style={{ marginRight: 10, marginLeft: 5 }}> <Tooltip
                        placement="bottom"
                        title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: nodeData.description }}></div>}
                        color="#fff"
                        zIndex={9999}
                        overlayClassName="page-title-tooltip"
                    >
                        <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 16 }} />
                    </Tooltip>
            </span>
                }
          </span>
            {nodeData?.namePath?.includes("Созданные метрики") && nodeData.title !== "Созданные метрики" && (
                <>
                    {isHovered && (
                        <span className={"settings"} style={{ marginRight: "5px",display:"inline-flex",columnGap:"2px",paddingLeft:"20px" }} onClick={(e) => e.preventDefault()}>
              <span onMouseEnter={() => isBlocked.current = true} onMouseLeave={()=>isBlocked.current = false}>
                <SettingOutlined  onClick={() => handleEditMetric(nodeData)} style={{ color: 'rgb(84 83 83)',fontSize: 16, width: "20px" }} />
              </span>

              <Popconfirm
                  title="Удалить?"
                  onConfirm={handleRemoveMetric}
                  okText="Да"
                  onOpenChange={handleRemoveMetricOpenChange}
                  cancelText="Нет"
              >
                <span>
                  <DeleteOutlined style={{ color: 'rgb(84 83 83)', fontSize: 16, width: "20px" }} />
                </span>
              </Popconfirm>
            </span>
                    )}
                </>
            )}
    </span>
    )
}
export default MetricItem
