export const checkValueForStaffForms = (value: any) => {
  if (!value) {
    return null;
  }

  if (typeof value === "object" && value.length === 0) {
    return null;
  }

  if (value[0] === 0) {
    return 0;
  }

  return value;
};

export const updateDurationIntervals = [
  { id: 20, name: "1 раз в 20 минут" },
  { id: 60, name: "1 раз в час" },
  { id: 180, name: "1 раз в 3 часа" },
  { id: 360, name: "1 раз в 6 часов" },
  { id: 540, name: "1 раз в 9 часов" },
  { id: 720, name: "1 раз в 12 часов" },
  { id: 1440, name: "1 раз в сутки" }
]

export const stringIsUrl = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  return pattern.test(str);
};
export const filtersMap: Record<string | number, string[]>  = {
  "/users/universal": [
    "Пользователи",
    "Регистрации",
    "Заявки",
    "Шаги воронки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Просмотр страницы после регистрации"
  ],
  "/users/order-reports": [
    "Пользователи",
    "Регистрации",
    "Шаги воронки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Посещения страницы"
  ],
  "/users/ad-reports":[
    "Пользователи",
    "Регистрации",
    "Заявки",
    "Шаги воронки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Посещения страницы"
  ],
  "/users/payment-reports": [
    "Пользователи",
    "Регистрации",
    "Шаги воронки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Посещения страницы"
  ],
  "/users/webinar-reports": [
    "Пользователи",
    "Регистрации",
    "Шаги воронки",
    "Заказы",
    "Платежи",
    "Вебинары",
    "Реклама",
    "Посещения страницы"
  ],
}
interface Filters {
  [key: string]: {
    [key: string]: string[];
  };
}
export const currencies = [
  { value: 1, label: "Рубль РФ" },
  { value: 2, label: "Доллар США" },
  { value: 3, label: "Евро" },
  { value: 4, label: "Тенге" },
  { value: 5, label: "Гривна" },
  { value: 6, label: "Беларусский рубль" },
];

export const filtersDependsOnNamePath:Filters = {
  "/users/universal": {
    "Регистрации":["Пользователи","Регистрации"],
    "Заявки":["Заявки"],
    "Заказы":["Пользователи","Заказы"],
    "Платежи":["Пользователи","Заказы","Платежи"],
    "Вебинары":["Пользователи","Заказы","Платежи","Вебинары"],
    "Просмотры страниц после рег.":["Пользователи","Просмотры страниц"]
  },
  "/users/order-reports": {
    "Заказы":["Пользователи","Заказы"],
    "Платежи":["Пользователи","Заказы","Платежи"],
  },
  "/users/ad-reports": {
    "Регистрации":["Пользователи","Регистрации"],
    "Заявки":["Заявки"],
    "Заказы":["Пользователи","Заказы"],
    "Платежи":["Пользователи","Заказы","Платежи"],
    "Вебинары":["Пользователи","Заказы","Платежи","Вебинары"],
    "Реклама":["Реклама"],
    "Просмотры страниц после рег.":["Пользователи","Просмотры страниц"]
  },
  "/users/payment-reports": {
    "Платежи":["Пользователи","Заказы","Платежи"],
  },
  "/users/webinar-reports": {
    "Заказы":["Пользователи","Заказы"],
    "Платежи":["Пользователи","Заказы","Платежи"],
    "Вебинары":["Пользователи","Заказы","Платежи","Вебинары"],
  },
}
export const termsForFilteringCustomMetrics = [
  "creator_to_buyer_converse",
  "web_5min_users_part",
  "orders_price",
  "forders_price",
  "orders_creators_price",
  "web_15min_users_part",
  "web_60min_users_part",
  "web_60min_g_users_part",
  "web_1min_users_part",
  "payments_price",
  "buyers_price",
  "profit",
  "no_step5_to_no_app_converse",
  "profit_per_reg",
  "profit_per_user",
  "web_user_to_no_app_converse",
  "web_120min_g_users_part",
  "orders_sum_avg",
  "orders_sum_avg_user",
  "user_to_creator_converse",
  "orders_to_forders_converse",
  "user_to_buyer_converse",
  "payments_sum_per_reg",
  "new_users_part",
  "user_to_web_user_converse",
  "web_user_to_creator_converse",
  "romi",
  "roas",
  "drr",
  "web_user_to_clicker_converse",
  "clicker_to_creator_converse",
  "web_user_price",
  "reg_price",
  "user_price",
  "paid_regs_part",
  "free_regs_part",
  "payments_sum_avg",
  "cpm",
  "cpc",
  "ctr",
  "ads_click_to_reg_converse",
  "user_to_no_app_converse",
  "no_step1_to_no_step2_converse",
  "no_step2_to_no_step3_converse",
  "orders_to_payments_converse",
  "user_to_refunder_converse",
  "buyer_to_refunder_converse",
  "user_to_no_step1_converse",
  "no_step3_to_no_step4_converse",
  "no_step4_to_no_step5_converse",
  "orders_sum_avg_2",
  "orders_to_forders_converse_2",
  "payments_sum_avg_2",
  "creator_to_buyer_converse_2",
  "orders_to_payments_converse_2",
  "buyer_to_refunder_converse_2",
  "web_30min_users_part_4",
  "web_30min_g_users_part_4",
  "web_180min_g_users_part_4",
  "web_240min_g_users_part_4",
  "payments_sum_avg_3",
  "buyer_to_refunder_converse_3",
  "web_1min_users_part_4",
  "web_5min_users_part_4",
  "web_15min_users_part_4",
  "web_60min_users_part_4",
  "web_60min_g_users_part_4",
  "web_120min_g_users_part_4",
  "web_user_to_clicker_converse_4",
  "orders_sum_avg_4",
  "orders_to_forders_converse_4",
  "orders_sum_avg_web_4",
  "web_user_to_creator_converse_4",
  "clicker_to_creator_converse_4",
  "payments_sum_avg_4",
  "buyer_to_refunder_converse_4",
  "creator_to_buyer_converse_4",
  "web_to_buyer_converse_4",
  "orders_to_payments_converse_4",
  "payments_sum_per_web_4",
  "web_to_refunder_converse_4",
  "web_30min_users_part",
  "web_30min_g_users_part",
  "web_180min_g_users_part",
  "web_240min_g_users_part",
  "payments_sum_per_user",
  "visitor_to_user_converse",
  "visit_to_reg_converse",
  "payments_sum_partial_remain",
  "payments_sum_partial_remain_2",
  "payments_sum_partial_remain_4",
  "reg_visit_to_reg_converse",
  "reg_visitor_to_user_converse"
]
export const summaryMetrics = [
  "regs_count",
  "users_count",
  "old_users_count",
  "new_users_count",
  "paid_regs_count",
  "free_regs_count",
  "no_step1_count",
  "no_step2_count",
  "no_step3_count",
  "no_step4_count",
  "no_step5_count",
  "no_app_count",
  "orders_count",
  "orders_sum",
  "orders_creators_count",
  "forders_count",
  "forders_sum",
  "porders_count",
  "porders_sum",
  "worders_count",
  "worders_sum",
  "rorders_sum",
  "aorders_count",
  "aorders_sum",
  "payments_count",
  "payments_sum",
  "payments_sum_with_commission",
    "payments_sum_partial",
  "payments_commission_sum",
  "buyers_count",
  "refunds_count",
  "refunds_sum",
  "buyers_with_refund_count",
  "web_users_count",
  "web_1min_users_count",
    "web_1_users_count",
  "web_2_users_count",
  "web_3_users_count",
  "web_4_users_count",
  "web_5_users_count",
  "web_6_users_count",
  "web_7_users_count",
    "web_all_users_count",
  "web_5min_users_count",
  "web_15min_users_count",
  "web_60min_users_count",
  "web_60min_g_users_count",
  "web_120min_g_users_count",
  "web_clickers_count",
  "ads_clicks_count",
  "ads_impressions",
  "ads_spend",
  "profit",
]
export const _debounce = (inner: () => any, ms = 0) => {
  let timer: NodeJS.Timeout | null = null;
  let resolves: any = [];

  return () => {
    clearTimeout(timer || undefined);
    timer = setTimeout(() => {
      const result = inner();
      resolves.forEach((r: any) => r(result));
      resolves = [];
    }, ms);

    return new Promise((resolve) => resolves.push(resolve));
  };
};
