import React, { FC, useState, useEffect } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Alert, Layout, Spin } from "antd"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import Nav from "../nav/Nav"

type Props = {
    children: React.ReactElement;
    withHead?: boolean;
    withFooter?: boolean;
    clean?: boolean;
    pageTitle?: string;
};

const MainLayout: FC<Props> = ({
                                   children,
                                   withHead = true,
                                   withFooter = false,
                                   clean = false,
                                   pageTitle,
                               }) => {
    document.title = pageTitle ? `${pageTitle}` : "SCAN Аналитика"

    const { isGlobalLoading } = useTypedSelector((state) => state.app)
    const [collapsed, setCollapsed] = useState(false)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        const lastClosed = localStorage.getItem("bannerClosedAt")
        if (!lastClosed || (Date.now() - parseInt(lastClosed)) > 14 * 24 * 60 * 60 * 1000) {
            setShowBanner(true)
        }
    }, [])

    const handleBannerClose = () => {
        setShowBanner(false)
        localStorage.setItem("bannerClosedAt", Date.now().toString())
    }

    return isGlobalLoading ? (
        <div className="global-loading-container">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
    ) : (
        <Layout style={{ minHeight: "100vh" }}>
            {withHead ? <Header /> : null}

            <Layout>
                {!clean && selectedSchool && (
                    <Layout.Sider
                        theme="dark"
                        collapsible
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                    >
                        <Nav />
                    </Layout.Sider>
                )}

                <Layout.Content className="layout__content">
                    <Alert
                        message={
                            <>
                                Подпишитесь на <a
                                href="https://t.me/scananalytics"
                                target="_blank"
                                style={{color: "blue"}}
                            >
                                {" " + "наш телеграм канал,"}
                            </a> чтобы следить за новостями сервиса.
                            </>
                        }
                        type="info"
                        style={{width: "100%", minHeight: "60px", fontSize: "18px", textAlign: "center"}}
                    />
                    {children}
                </Layout.Content>

                {withFooter && <Footer/>}
            </Layout>

            {showBanner && (
                <div className={"banner"}>
                    <a href="https://vk.com/@nr_target-special" target="_blank">
                        <img src="/assets/img/nr.jpg" alt="Banner"/>
                    </a>
                    <span className={"closeButton"} onClick={handleBannerClose}>×</span>
                </div>
            )}
        </Layout>
    )
}

export default MainLayout
