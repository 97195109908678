
import React, {FC, useEffect, useRef, useState} from "react"

import {CloseOutlined, FilterOutlined, LinkOutlined, PlusOutlined} from "@ant-design/icons"
import {Button, Checkbox, Drawer, Dropdown, Input, InputNumber, Menu, Modal, Popover, Radio, Select, Space} from "antd"
import TextArea from "antd/es/input/TextArea"

import ConversationUI, {actionsWithN} from "./create-mertrics/ConversationUI"
import FillingConditions from "./create-mertrics/FillingConditions"
import InputWithColorPreview from "./create-mertrics/InputWithColorPreview"
import ModalSettings from "./create-metrics-modals/ModalSettings"
import Filter from "./params/filter"
import {filterTree, getMetricFormula} from "../../../../helpers/utils/functions"
import {generateListKeys, prepareData } from "../../../../helpers/utils/reports"
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/utils/ui"
import { unApi } from "../../api/endpoints/reports/unApi"
import { dataItemsJsonUniversal, listKeysJsonUniversal } from "../../constants/metrics"
import { metrics as staticMetricsUniversal, groupingsData as staticGroupingsUniversal } from "../../constants/reports"
import { metrics as staticMetricsWebinar, groupingsData as staticGroupingsWebinar } from "../../constants/reportsWebinar"
import { metrics as staticMetricsAd, groupingsData as staticGroupingsAd } from "../../constants/reportsAD"
import { metrics as staticMetricsOrder, groupingsData as staticGroupingsOrder } from "../../constants/reportsOrder"
import { metrics as staticMetricsPayment, groupingsData as staticGroupingsPayment } from "../../constants/reportsPayment"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { RadioChangeEvent } from "antd/es"
import {filtersDependsOnNamePath, filtersMap, termsForFilteringCustomMetrics} from "../../../../helpers/utils/data";
import {IFilter} from "../../../../app/providers/redux/slices/reports/params/filter/types";
import {MetricsPaths} from "../../../../helpers/utils/enums";
import {getCookie} from "../../../../helpers/utils/cookies";
type Props = {
    visible: boolean;
    title:string
    setVisible:any;
}
const metricOptions = [
    { value: "conversions", label: "Конверсии" },
    { value: "users", label: "Пользователи" },
    { value: "registrations", label: "Регистрации" },
    { value: "orders", label: "Заказы" },
    { value: "sessions", label: "Сессии" },
    { value: "payments", label: "Платежи" },
    { value: "webinars", label: "Вебинары" },
]
const applicationsMetric = {
        name: "Заявки",
        description: "",
        children: [
            {
                name: "Заявки",
                description:
                    "Количество людей, кто создал заявку после регистрации",
                dataKey: "no_app_count",
            },
        ],
    }
    const universalMetrics = [...staticMetricsUniversal]
const adMetrics = [...staticMetricsAd]
universalMetrics.splice(1, 0, applicationsMetric)
adMetrics.splice(1, 0, applicationsMetric)
export const staticMetrics = {
    [MetricsPaths.WebinarReports] :staticMetricsWebinar,
    [MetricsPaths.Universal]:universalMetrics,
    [MetricsPaths.OrderReports]:staticMetricsOrder,
    [MetricsPaths.PaymentReports]:staticMetricsPayment,
    [MetricsPaths.AdReports]:adMetrics
}
export const StaticGroupings = {
    [MetricsPaths.WebinarReports] :staticGroupingsWebinar,
    [MetricsPaths.Universal]:staticGroupingsUniversal,
    [MetricsPaths.OrderReports]:staticGroupingsOrder,
    [MetricsPaths.PaymentReports]:staticGroupingsPayment,
    [MetricsPaths.AdReports]:staticGroupingsAd
}
export
enum Units {
    Quantity = "Количество",
    Currency = "Валюта",
    Percentage = "%"
}
enum FillingOption {
    Full = 'full',
    ValueBased = 'valueBased'
}

interface ModalState {
    name: string
    plan: string
    description: string
    whatWeCount?: number
    sum?: number
    conditionType: string
    conditionValue: null | number
    unit: Units | null
    isFilling: boolean
}

interface SelectedItem {
    namePath: string[];
}
const CreateMetricsModal = ({ visible,setVisible,title }: Props) => {
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

    const { getCreatedMetrics } = useActions()
    const [isBlocked,setIsBlocked] = useState(false)
    const [ modalState, setModalState ] = useState<ModalState>({
        name: "",
        plan:"",
        description: "",
        whatWeCount: undefined,
        sum: undefined,
        conditionType: "equal",
        conditionValue: null,
        unit:Units.Quantity,
        isFilling:false
    })
    const { metrics,createdMetrics } = useTypedSelector((state) => state.table)
    const [ fillingConditions, setFillingConditions ] = useState([
        { from: "", to: "", value: "#000000",id:1 },
    ])
    const [ converseData,setConverseData ] = useState<any>({
        selectedAction:{ id:"",label:"" },
        constant_argument:null,
    })
    const [ isDrawerVisible,setIsDrawerVisible ] = useState(false)
    const [ selected,setSelected ] = useState([])
    const { filters } = useTypedSelector((state) => state.filter)
    const [initialFilters,setInitialFilters] = useState<IFilter[]>([])
    const { setFilters } = useActions()
    useEffect(()=>{
        if(visible){
            setInitialFilters(filters)
            setFilters([])
        }
    },[visible])
    useEffect(()=>{
        return () => {
            setFilters(initialFilters)
        }
    },[initialFilters])
    const [ isVisibleFilter,setIsVisibleFilter ] = useState(false)
    const [ isConverse,setIsConverse ] = useState(false)
    const [ backgroundColor,setBackgroundColor ] = useState("#fffffff")
    const handleInputChange = (e:any) => {
        const { name, value } = e.target
        setModalState(prevState => ({ ...prevState, [name]: value }))
    }
    const handleSelectChange = (value:string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setModalState(prevState => ({ ...prevState, whatWeCount: value }))
    }
    const handleConditionChange = (value:any) => {
        setModalState(prevState => ({ ...prevState, conditionValue: value }))
    }
    const addCondition = () => {
        if(fillingConditions.length === 10){
            showErrorMessage("Достигнут лимит по условиям 10 штук.")
            return
        }
        setFillingConditions((prevConditions) => [ ...prevConditions, { from: "", to: "", value: "#000000",id:prevConditions.length + 1 } ])
    }

    const handleFillingValueChange = (index:number, field:string, newValue:string) => {
        setFillingConditions((prevConditions) => {
            const updatedConditions = [ ...prevConditions ]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatedConditions[index][field] = newValue
            return updatedConditions
        })
    }


    const handleConditionTypeChange = (e:any) => {
        setModalState(prevState => ({ ...prevState, conditionType: e.target.value }))
    }

    const resetFilter = () => {
        setModalState(prevState => ({ ...prevState, conditionValue: null }))
    }


    const handleApply = () => {
        console.log("Condition applied:", modalState.conditionType, modalState.conditionValue)
    }

    const ConversationsUi = (
        <div style={{ marginTop:"10px" }}>
            <Select placeholder="Какой метрики?" style={{ width: 200, marginRight: 16 }}>
            </Select>
            <Select placeholder="От какой метрики" style={{ width: 200 }}>
            </Select>
        </div>
    )
    const handleSubmit = async () => {
        if(selectedSchool?.id && !isBlocked){
            try{
                setIsBlocked(true)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const baseMetricsName = selected[0]?.dataKey
                const dataKeysArray = isConverse ? Object.values(converseData.inputStates).map((item: any) => item.selected[0]?.dataKey) : []
                const metric_formula = isConverse ? getMetricFormula(converseData): null
                if(isConverse && dataKeysArray.length < 2 && !actionsWithN.includes(converseData.selectedAction.id)){
                    showErrorMessage("Выберите метрику")
                    return
                }
                if(isConverse && converseData.inputStates && !actionsWithN.includes(converseData.selectedAction.id)){
                    for (let key in converseData.inputStates) {
                        if (converseData.inputStates[key].selected.length === 0) {
                            showErrorMessage("Выберите метрику")
                            return
                        }
                    }
                }
                if(modalState.name.trim().length === 0){
                    showErrorMessage("Введите название метрики")
                    return
                }
                if(!baseMetricsName && !isConverse){
                    showErrorMessage("Не выбрана базовая метрика")
                    return
                }
                const visual_parameters = JSON.stringify({
                    fillingConditions,
                    backgroundColor,
                    plan:modalState.plan,
                    isFilling:modalState.isFilling,
                    unit:modalState.unit,
                    ...(!isConverse && { base_metric_name: baseMetricsName }),
                    ...(isConverse && {metric_formula})
                })
                const values = {
                    school_id:selectedSchool.id,
                    metric_type_id:isConverse ? 2 : 1,
                    custom_metric_name:modalState.name,
                    description:modalState.description || null,
                    base_metric_name: isConverse ? null : baseMetricsName,
                    filter_json:isConverse ? null : JSON.stringify(filters),
                    visual_parameters:visual_parameters,
                    metric_formula:metric_formula,
                    constant_argument:isConverse ? converseData.constant_argument : null
                }
                const response =  await unApi.addCustomMetric(values,location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : location.pathname)
                if(response.status === 200){
                    setFilters([])
                    showSuccessMessage("Метрика успешнно добавлена")
                    setTimeout(()=>getCreatedMetrics(selectedSchool.id),150)
                    setVisible(false)
                }
            }catch(err){
                console.log(err)
                showErrorMessage("Произошла ошибка во время добавления метрики.")
            }finally{
                setIsBlocked(false)
            }
        }

    }
    const handleDeleteCondition = (id:number) => {
        setFillingConditions((prevConditions) => prevConditions.filter((condition) => condition.id !== id))
    }

    const conversationMetricsData = prepareData(
        [ ...staticMetrics[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname ) as keyof typeof staticMetrics], ...structuredClone(createdMetrics) ],
        "",
        [],
        false
    )?.filter((el:{name:string})=>el.name !== "Шаги воронки")
    const listKeysCustomMetric = generateListKeys(structuredClone(createdMetrics))

    const handleClearCondition = (index:number) => {
        setFillingConditions(currentConditions =>
            currentConditions.map((condition, i) =>
                i === index ? { ...condition, from: "", to: "", value: "#000000" } : condition
            )
        )
    }
    const filtersArray = React.useMemo(() => {
        const selectedTyped = selected as SelectedItem[]
        if (selectedTyped?.length > 0 && selectedTyped[0]?.namePath && selectedTyped[0]?.namePath?.length > 0) {
            const namePathKey = selectedTyped[0]?.namePath[0] as keyof typeof filtersDependsOnNamePath["/users/universal"];
            return filtersDependsOnNamePath[location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname]?.[namePathKey] || [];
        }
        return filtersMap[location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname] || []
    }, [location.pathname, selected])
    const handleOpenDrawer = () => {
        setIsDrawerVisible(true)
    }
    const iframeRef = useRef(null);
    const openLink = () => {
        window.open(`https://help.scananalytics.ru/kastomnye-metriki`, '_blank');
    }
    const onClose = () => {
        setIsDrawerVisible(false)
    }
    useEffect(() => {
        const drawerBody = document.querySelector('.ant-drawer-body')
        if (drawerBody) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            drawerBody.style.padding = '0'
        }
        function postMessageToIframe(data:any) {
            const iframe = iframeRef.current
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            if (iframe && iframe.contentWindow) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                iframe.contentWindow.postMessage(data, 'https://lk.scananalytics.ru')
            }
        }
        postMessageToIframe("deleteElement")

    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
        <Modal style={{ top:20 }} width={"55%"} title={<span style={{ fontWeight:"700" }}>{title}
            <Button type="primary" onClick={handleOpenDrawer} style={{marginLeft:"10px",fontWeight:"600",
                backgroundColor: "rgb(76, 175, 80)",
                borderColor: "rgb(76, 175, 80)",
                borderRadius:"30px"
            }}>
    Как создать?
  </Button></span>} onCancel={()=>setVisible(false)} open={visible}
               footer={[
                   <div style={{ display: "flex", justifyContent: "space-between" }}>
                       <Button type={"primary"} onClick={handleSubmit} key="save">Сохранить</Button>
                   </div>
               ]}
        >
            <div style={{ display:"flex",columnGap:"20px" }}>
            <div style={{ width:"65%" }}>
                <div style={{ fontWeight:"700",marginBottom:"8px" }}>Название метрики</div>
                <Input
                    name="name"
                    value={modalState.name}
                    maxLength={100}
                    style={{ width:"100%" }}
                    onChange={handleInputChange}
                />
            </div>
                <div style={{ width:"35%" }}>
                    <div style={{ fontWeight:"700",marginBottom:"8px" }}>Описание</div>
                    <TextArea
                        maxLength={300}
                        style={{ height: "32px",width:"100%" }}
                        name="description"
                        value={modalState.description}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div>
                <div style={{ display:"flex",columnGap:"14px",marginTop:"26px" }}>
                    <div>
                        <Radio.Group
                            value={isConverse}
                            onChange={(e) => setIsConverse(e.target.value)}
                        >
                            <Radio value={false}>Добавить фильтр к метрике</Radio>
                            <Radio value={true}>Рассчитать метрику</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {!isConverse && <div>
                    <ModalSettings selectedProps={selected} setSelectedProps={setSelected} dataItems={filterTree(JSON.parse(dataItemsJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof dataItemsJsonUniversal]),termsForFilteringCustomMetrics)} listKeys={JSON.parse(listKeysJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof listKeysJsonUniversal])} defSelected={[]} defChecked={selected.map((el:{key:string})=>el?.key || "")}  />
                </div>}
                {isConverse && <ConversationUI setConverseData={setConverseData}  dataItems={conversationMetricsData} listKeys={[...JSON.parse(listKeysJsonUniversal[(location.pathname.includes("dashboard") ? getCookie("dashboardPathname")! : window.location.pathname) as keyof typeof listKeysJsonUniversal]),...listKeysCustomMetric]} defSelected={[]} defChecked={[]} />}
                {!isConverse && (
                <div style={{ marginTop:"24px" }}>
                    <Filter
                        notParams={true}
                        withSegment={false}
                        filtersFilter={filtersArray}
                        isCompare={false}
                    />
                </div>
                    )}
                <div style={{ display:"flex",alignItems:"center",columnGap:"40px",marginTop:"10px" }}>
                    <div style={{ display:"flex",columnGap:"40px" }}>
                        <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                            <span style={{ fontWeight:"700" }}>Фон столбца:</span>
                            <InputWithColorPreview value={backgroundColor} onChange={setBackgroundColor}  />
                        </div>
                    </div>
                    <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                        <span style={{ fontWeight:"700" }}>План</span>
                        <div style={{ display:"flex",alignItems:"center",columnGap:"5px" }}>
                            <Input value={modalState.plan} onChange={(e)=>setModalState(prev=>({ ...prev,plan:e.target.value }))} style={{ width:"86px" }} />
                            <span>на 1 день</span>
                        </div>
                    </div>
                    <div style={{ display:"flex",flexDirection:"column",rowGap:"8px" }}>
                        <span style={{ fontWeight:"700" }}>Единица измерения</span>
                        <div style={{ display:"flex",alignItems:"center",columnGap:"5px" }}>
                            <Select
                                defaultValue={modalState.unit}
                                placeholder="Выберите единицу измерения"
                                onChange={(value: Units) => {
                                    setModalState(prevState => ({
                                        ...prevState,
                                        unit: value
                                    }));
                                }}
                                style={{ width: 200 }}
                            >
                                {Object.entries(Units).map(([key, value]) => (
                                    <Select.Option key={key} value={key}>
                                        {value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div style={{ display:"flex",rowGap:"10px",flexDirection:"column",marginTop:"24px" }}>
                    <span style={{ fontWeight:"700" }}>Условия заливки ячейки:</span>
                        <Radio.Group onChange={(e: RadioChangeEvent) => {
                            const fillingOption: FillingOption = e.target.value as FillingOption
                            setModalState(prevState => ({
                                ...prevState,
                                isFilling: fillingOption === FillingOption.ValueBased
                            }))
                        }} value={modalState.isFilling ? 'valueBased' : 'full'}>
                            <Space direction="vertical">
                            <Radio value="full">Заливать всю ячейку</Radio>
                            <Radio value="valueBased">Заливать ячейку от значения</Radio>
                            </Space>
                        </Radio.Group>
                    {fillingConditions.map((condition, index) => (
                        <FillingConditions
                            key={condition.id}
                            from={condition.from}
                            to={condition.to}
                            value={condition.value}
                            id={condition.id}
                            onClear={() => handleClearCondition(index)}
                            onDelete={()=>handleDeleteCondition(condition.id)}
                            onChange={(field, newValue) => handleFillingValueChange(index, field, newValue)}
                        />
                    ))}
                </div>
                <Space style={{ marginTop:"16px" }}>
                    <span style={{ fontWeight:"600" }}>Добавить условие заливки</span>
                    <Button onClick={addCondition} icon={<PlusOutlined />} type="primary" shape="circle" />
                </Space>
            </div>
            <Drawer
                placement="right"
                onClose={onClose}
                visible={isDrawerVisible}
                width={650}
                rootStyle={{ padding:0,overflow:"hidden" }}
            >
                <div style={{ position: 'absolute', top: 30, left: -40, zIndex: 1000 }}>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={onClose}
                        shape={"circle"}
                        style={{ display: 'block', marginBottom: 10 }}
                    />
                    <Button
                        icon={<LinkOutlined />}
                        onClick={openLink}
                        shape={"circle"}
                        style={{ display: 'block' }}
                    />
                </div>
                <div style={{ marginTop:"10px" }}></div>
                <iframe
                    style={{ border:"none" }}
                    ref={iframeRef}
                    src={`https://help.scananalytics.ru/kastomnye-metriki?timestamp=${new Date().getTime()}`}
                    width="100%"
                    height="100%"

                />
            </Drawer>
        </Modal>
    )
}

export default CreateMetricsModal
