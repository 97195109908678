import React from 'react';
import ReactDOM from 'react-dom';
import { LoadingOutlined } from '@ant-design/icons';
type Props = {
    isOpen:boolean
}
const Mask = ({ isOpen }:Props) => {
    if (!isOpen) {
        return null;
    }

    const overlayStyles: React.CSSProperties = {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
    };

    const loadingStyles: React.CSSProperties = {
        fontSize: 76,color:"#40a9ff",position:"fixed",top:"50%",left:"50%"
    };

    return ReactDOM.createPortal(
        <div style={overlayStyles}>
            <LoadingOutlined style={loadingStyles} spin />
        </div>,
        document.body
    );
};

export default Mask;
