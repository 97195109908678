import React from "react";
import {createPortal} from "react-dom";
interface Props {
    left: number;
    top: number;
    content: string;
    color:string[] | [];
}
export const TitleChart = ({ left, top, content,color }:Props) => {
    const titleStyle: React.CSSProperties = {
        position: 'absolute',
        height: 288,
        left: left - 20 + 'px',
        top: top - 288 + 'px',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        fontSize: '11px',
        fontWeight: 'bold',
    };
    const contentParts = content.split(' | ');
    return createPortal(
        <div style={titleStyle}>
            {contentParts.map((part, index) => (
                <span key={index} style={{ color: color[index] || '#263238' }}>{index > 0 && ' | '}{part}</span>
            ))}
        </div>,
        document.body
    );
};
